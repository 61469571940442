import React, { useContext, useEffect, useState } from "react";

import logo from "./logo.svg";
import "./App.css";

const baseUrl = "https://jw3jjagqn5.execute-api.eu-west-1.amazonaws.com/prod";

function App() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ssn, setSsn] = useState("");
  const [id, setId] = useState("");
  const [unique, setUnique] = useState("");
  const [authResponse, setAuthResponse] = useState(null);
  const [isRedirected, setIsRedirected] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);
  const [done, setDone] = useState(false);
  const [didRun, setDidRun] = useState(false);
  const [doneText, setDoneText] = useState("");
  const [error, setError] = useState("");
  const [originalUrl, setOriginalUrl] = useState(window.location.href);
  const [isDone, setIsDone] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (window.location.href && window.location.href != '') {
      if (window.location.pathname && !didRun) {
        const splitted = window.location.pathname.split('/')
        if (splitted[1] && splitted[3] && splitted[5] !== 'done') {
          setDidRun(true);
          const sessionId = splitted[3]
          setSessionId(sessionId);
          window.location.href = `${originalUrl}/done`;
        }
      }
      if (window.location.href.includes('/done')) {
        const splitted = window.location.pathname.split('/')
        setSessionId(splitted[3]);
        setIsDone(true);
      }
      if (!window.location.href.includes('autostarttoken')) {
        // setOriginalUrl(window.location.href);
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    console.log('### ORIGINAL URL ###', originalUrl)
  }, [originalUrl]);

  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  useEffect(() => {
    console.log("### ssn ###", ssn);
  }, [ssn]);

  useEffect(() => {
    if (isDone) {
      console.log("### IS DONE ###", isDone, sessionId);
      setAuthResponse({ sessionId: sessionId, noRedirect: true })
      setIsLoading(true);
    }
  }, [isDone]);

  let interval = null;
  useEffect(() => {
    (async function () {
      console.log("### AUTH RESPONSE ###", authResponse);
      if (authResponse) {
        interval = setInterval(async () => {
          await handleCollectBankId();
        }, 3000);
      }
    })();
  }, [authResponse]);

  const handleCollectBankId = async () => {
    if (authResponse) {
      if (!authResponse.noRedirect) {
        window.location.href = `bankid:///?autostarttoken=${authResponse.autoStartToken}&redirect=${originalUrl + '/' + authResponse.sessionId}`;
      } else {
        collect(authResponse.sessionId).then(async (response) => {
          console.log("response", response);
          if (response.userAttributes) {
            console.log("YAY!");
            setAuthResponse(null);
            clearInterval(interval);
            console.log("SET TO DONE!!");
            const res = await setToDone({
              personalNumber: response.userAttributes.personalNumber,
              name: response.userAttributes.name,
            });

            console.log("setToDone", res);

            if (res.success) {
              setIsLoading(false);
              setDone(true);
              setIsCollecting(false);
              if (res.alreadyDone) {
                setDoneText("Du har redan verifierat dig!");
              } else {
                setDoneText("Bra! Du är nu verifierad!");
              }
            } else {
              setDone(false);
              setIsCollecting(false);
              setIsLoading(false);
              if (res.errorMessage) {
                setError(res.errorMessage);
              } else {
                setError("Något gick fel...");
              }
            }
          }
        });
      }
    }
  };

  const collect = async (id) => {
    console.log("appContext collect");
    console.log("GPGOGOGO");
    const rawResponse = await fetch(`${baseUrl}/collect/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: false,
      }),
    });
    console.log("RAWERR", rawResponse);
    const response = await rawResponse.json();
    console.log("RAWERR2", response);
    return response;
  };

  const authenticateLookup = async () => {
    // first check if there is a record
    const id = window.location.pathname
      ? window.location.pathname.replace("/", "")
      : null;

    if (!id) {
      alert("Felaktig länk gå tillbaka till SMS:et och tryck på den igen.");
      return false;
    }

    setIsCollecting(true);
    const responseLookup = await getLookup({ id: id, phone: phoneNumber });
    console.log("RESPONSE", responseLookup);

    if (responseLookup.success) {
      console.log("### handleAuth ###");
      const response = await authAndCollect({ socialSecurityNumber: ssn });
      console.log("RESPNOSEEE", response);
      if (response.sessionId) {
        setAuthResponse(response);
      }
    }
  };

  const authAndCollect = async ({ socialSecurityNumber }) => {
    console.log("GPGOGOGO");
    const rawResponse = await fetch(
      `${baseUrl}/authenticate-and-collect/${socialSecurityNumber}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ssn: socialSecurityNumber,
        }),
      }
    );
    console.log("RAWERR", rawResponse);
    const response = await rawResponse.json();
    console.log("RAWERR2", response);
    return response;
  };

  const getLookup = async ({ id, phone }) => {
    console.log(`${baseUrl}/get-lookup-by-phone/${id.replace("/", "")}`);
    const rawResponse = await fetch(
      `${baseUrl}/get-lookup-by-phone/${id.replace("/", "")}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("FIRST", rawResponse);
    const response = await rawResponse.json();
    console.log("SECOND", response);
    return response;
  };

  const setToDone = async ({ personalNumber, name }) => {
    console.log("SET TO DONE", personalNumber, name);
    const id = window.location.pathname.split('/')[1]
    const rawResponse = await fetch(
      `${baseUrl}/public-quick-lookup-done/${id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phoneNumber,
          ssn: personalNumber,
          name: name,
          id: id.replaceAll("/", ""),
        }),
      }
    );
    console.log("AFTER?", rawResponse);
    try {
      console.log("rawResponserawResponse", rawResponse);
      const response = await rawResponse.json();
      return response;
    } catch (e) {
      alert("! ERROR !");
      alert(JSON.stringify(e));
      return false;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  };

  return (
    <div className="App" style={{ backgroundColor: "#7544a7" }}>
      <header className="App-header" style={{ backgroundColor: "#FFF" }}>
        <img
          style={{ width: 150, marginTop: 20, marginBottom: 20 }}
          src={require("./images/safedate-logga2-small.png")}
        />
      </header>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          background: "#7544a7",
          marginTop: "0px",
          display: "flex",
          flexDirection: "column",
          paddingLeft: isCollecting ? "0px" : "20px",
          paddingRight: isCollecting ? "0px" : "20px",
        }}
      >
        {isCollecting && !isLoading ? (
          <div
            style={{
              display: "flex",
              top: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "#7544a7",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 999,
            }}
          >
            <div style={{ width: "80%" }}>
              <img
                style={{ height: 155, marginRight: 10 }}
                src={require("./images/bankid_logo.png")}
              />
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 30,
                  marginTop: 0,
                }}
              >
                Vänta, BankID öppnas...
              </p>
              <div
                onClick={() => setIsCollecting(false)}
                target="_blank"
                style={{
                  padding: "3px 0px 3px 0px",
                  marginTop: 40,
                  backgroundColor: "rgb(167 105 231)",
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: 300,
                  marginBottom: 50,
                  boxShadow: "rgb(94 51 138) 5px 10px 10px",
                  cursor: "pointer",
                }}
              >
                <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                  Avbryt
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {isLoading ? <div
          style={{
            display: "flex",
            display: "flex",
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            // position: "absolute",
            // width: "100%",
            // height: "100%",
            background: "#7544a7",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: -20
          }}
        >
          <div style={{ width: "80%" }}>
            <img
              style={{ height: 155, marginRight: 10 }}
              src={require("./images/bankid_logo.png")}
            />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: 30,
                marginTop: 0,
              }}
            >
              Hämtar info ifrån BankID...
            </p>
          </div>
        </div> : null}
        {done ? (
          <div
            style={{
              display: "flex",
              top: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
              // position: "absolute",
              // width: "100%",
              // height: "100%",
              background: "#7544a7",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: -20
            }}
          >
            <div>
              <img
                style={{ height: 155, width: 155, marginTop: 20 }}
                src={require("./images/check.png")}
              />
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 30,
                  marginTop: 20,
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {doneText}
              </p>
              <div
                onClick={() => setDone(false)}
                target="_blank"
                style={{
                  padding: "3px 0px 3px 0px",
                  marginTop: 40,
                  backgroundColor: "rgb(167 105 231)",
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: 300,
                  marginBottom: 50,
                  boxShadow: "rgb(94 51 138) 5px 10px 10px",
                  cursor: "pointer",
                }}
              >
                <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                  OK
                </p>
              </div>
            </div>
          </div>
        ) : error ? (
          <div style={{
            display: "flex",
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            // position: "absolute",
            // width: "100%",
            // height: "100%",
            background: "#7544a7",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: 'column',
            marginLeft: -20
          }}>
            <p
              style={{
                color: "#FFF",
                marginTop: 100,
                fontSize: 20,
                padding: 20,
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 10,
                background: "#EF544F",
              }}
            >
              {error}
            </p>
            <div
              onClick={async () => {
                setDone(false);
                setError("");
                setIsLoading(false);
                setIsCollecting(false);
                setAuthResponse("");
              }}
              target="_blank"
              style={{
                padding: "3px 0px 3px 0px",
                marginTop: 10,
                backgroundColor: "#7544A7",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 1000,
              }}
            >
              <p style={{ color: "#FFF", fontSize: 18 }}>Gör om legitimering</p>
            </div>
          </div>
        ) : !isRedirected && !isCollecting ? (
          <>
            <h1 style={{ color: "#FFF", paddingTop: 40, marginTop: 0 }}>
              Trygghetskollen
            </h1>
            {/* <p style={{ color: "#FFF" }}>Ange ditt telefonnummer: </p>
            <input
              style={{
                padding: "16px 10px",
                textAlign: "center",
                fontSize: 24,
                border: "none",
                borderRadius: 1000,
                boxShadow: "rgb(68 28 108) inset 5px 10px 10px",
                background: "#4c2574",
                color: "#fff",
                alignSelf: "center",
              }}
              type="number"
              placeholder="0736811411"
              onChange={(e) => setPhoneNumber(e.target.value)}
            /> */}
            <p style={{ color: "#FFF", marginTop: 20 }}>
              Ange ditt personnummer:{" "}
            </p>
            <input
              style={{
                padding: "16px 10px",
                textAlign: "center",
                fontSize: 24,
                border: "none",
                borderRadius: 1000,
                boxShadow: "rgb(68 28 108) inset 5px 10px 10px",
                background: "#4c2574",
                color: "#fff",
                alignSelf: "center",
              }}
              type="phone"
              placeholder="19911027XXXX"
              onChange={(e) => setSsn(e.target.value)}
            />
            <button
              onClick={async () => await authenticateLookup()}
              target="_blank"
              style={{
                padding: "3px 30px 3px 14px",
                marginTop: 40,
                backgroundColor: "rgb(167 105 231)",
                // width: "80%",
                // marginLeft: "auto",
                // marginRight: "auto",
                borderRadius: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // maxWidth: 300,
                marginBottom: 50,
                boxShadow: "rgb(94 51 138) 5px 10px 10px",
                cursor: "pointer",
                border: "none",
                alignSelf: "center",
              }}
            >
              <img
                style={{ height: 45, marginRight: 5 }}
                src={require("./images/bankid_logo.png")}
              />
              <p style={{ color: "#FFF", fontSize: 18, fontWeight: "bold" }}>
                Verifiera
              </p>
            </button>
            <div style={{ paddingBottom: 50 }}>
              <h3 className="white">Varför ber vi dig göra detta?</h3>
              <p className="white">
                Någon har efterfrågat en trygghetskoll på dig via appen SafePal.
              </p>
              <h3 className="white">Vad är en trygghetskoll?</h3>
              <p className="white">
                När du verifierat dig med BankID i trygghetskollen skickas ditt
                fullständiga namn till appen SafePal och visas upp för den som
                efterfrågat det. Trygghetskoll finns för att alla ska ha möjlighet
                att veta att personen dem ska släppa in i sitt liv är den som
                hen utger sig för att vara.
              </p>
              <h3 className="white">
                Hur vet jag vem som efterfrågat trygghetskoll?
              </h3>
              <p className="white">
                Namnet på personen som efterfrågat trygghetskoll står i sms:et med
                länken du fick ifrån oss.
              </p>
              <h3 className="white">Integritet</h3>
              <p className="white">
                Det är enbart ditt namn som skickas till SafePal. Vi sparar inga
                andra uppgifter om dig. Väljer du att inte verifiera dig kommer
                vi inte spara några uppgifter om dig.
              </p>
            </div>
          </>
        ) : isCollecting ? (
          <div
            style={{
              display: "flex",
              marginTop: 100,
              padding: 20,
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 10,
              background: "#FFF",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: 20, marginRight: 10 }}
              src={require("./images/bankid_logo.png")}
            />
            <span>Signera i BankID-appen</span>
          </div>
        ) : done ? (
          <p
            style={{
              color: "#FFF",
              marginTop: 100,
              fontSize: 20,
              background: "#000",
              padding: 10,
            }}
          >
            Du är nu verifierad!
          </p>
        ) : null}
        {!done && !error ? (
          <div
            style={{
              background: "rgb(167, 105, 231)",
              padding: 20,
              alignItems: "center",
              left: -20,
              position: "relative",
              // width: ,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              // marginTop: "auto",
            }}
          >
            <p
              className="white"
              style={{ alignSelf: "flex-end", fontSize: 14 }}
            >
              {" "}
              © SafePal Company AB
            </p>
            <a
              style={{ fontSize: 14, textDecoration: "none", color: "#fff" }}
              href="https://www.yoursafepal.com"
              target="_blank"
            >
              Läs mer på yoursafepal.com
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default App;
